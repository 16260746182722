import React from 'react'
import HeroBanner from '../../components/HeroBanner'
import Layout from '../../components/Layout'
import { graphql } from 'gatsby'

const TermsOfUse = ({ data }) => {
  return (
    <Layout>
      <div className="Waiver">
        <HeroBanner
          image={data.file.childImageSharp.fluid}
          title="Terms of Use"
          caption=""
        />
        <div className="container content">
          <p>
            By participating in activities at OCRA, I acknowledge that anything
            I or my children do while we are at the OCR ACADEMY Facility may be
            an extreme test of my/their physical and/or mental abilities. I am
            not lying when I say that I/they am/are fit, because a certified
            medical Doctor gave us the A ok to train like a beast.I acknowledge
            further that training here although is awesome, does pose its own
            risks, not only to myself/my children but also my own property (i.e
            you may drop a weight on your much loved cell phone). As well
            someone may injure themselves and even worst case Death. So please
            train smart and don’t be that guy or girl, also you will not let
            your child be that guy or girl, and you swear that your, your child
            and the academy will be the same as when you arrived. As OCR Academy
            will not be held responsible for any injury or worst case death, by
            initialling you agree to this.
          </p>
          <p>
            I acknowledge that training in OCR ACADEMY group classes can be
            intense, so by you signing this you accept full responsibility for
            yourself and your child and that we (as in OCR Academy staff,
            volunteers and any and all of our peeps) are not responsible for
            what happens to you, your child or any of your possession’s while
            you participate in any of our awesome classes. We love you, and want
            what’s best for you so hopefully none of this applies to you, but we
            both know stuff can happen. By initialing you the undersigned
            acknowledge this.
          </p>
          <p>
            The undersigned also acknowledges that they may be photographed at
            any given time while training, and the OCR Academy will be using it
            for our awesome promo material. The undersigned can waive this at
            any given time. But why would you?
          </p>
          <h3>OCR ACADEMY CLUB POLICIES</h3>
          <p>
            Are you healthy? You got a clean bill of health? Good, because these
            classes are intense and since that is the case you accept that there
            are risks associated with doing our classes. If all is good please
            sign below.
          </p>
          <p>
            All of our Academics live by the rules that OCR ACADEMY set forth.
          </p>
          <p>
            OCR ACADEMY memberships have some rules, the customer protection act
            states that we must refund you in full your fees within 14 days. We
            want to make your experience with us enjoyable. We are willing to
            work with you in making the best decision possible. All we gotta do
            is sit down and chat, but since the law requires us to write stuff
            down please see below:
          </p>
          <p>
            If the cheque comes back, bro that sucks and we have been there, but
            a $30 NSF fee will be charged, we don’t want to but the banks charge
            us, and they don’t like us like we like you.
          </p>
          <p>
            If you don’t want to be with us anymore, well that sucks bro, just
            give us 2 weeks’ notice and promise not to talk bad about us to
            friends and family.
          </p>
          <p>
            If you are moving, we must first ask, why? Ottawa is awesome. But
            sometimes our work makes us move, and that’s cool you can transfer
            your account to someone else or simply cancel it all together.
          </p>
          <p>
            If you become ill or injure yourself from being awesome, just get us
            a doctor’s note and we can put your membership on hold or if you
            love someone enough, they can use your membership till your
            awesomeness returns.
          </p>
          <p>
            Like you, the OCR ACADEMY trainers have a life, we must plan our
            schedules accordingly, and we ask that you give us 24hrs notice if
            you can’t make an appointment. Because you wouldn’t like it if we
            decided not to open and not give you proper notice.
          </p>
          <p>
            We hope you enjoyed reading this wicked cool form and you completely
            understand what this waiver is saying, and release from liability
            and policies. By signing below I agree to abide by the terms and
            conditions set forth therein. By signing below I knowingly,
            voluntarily and depressingly waive any claim I may have against OCR
            ACADEMY for injury or damages I may sustain while participating in
            these fitness classes and/or workshops.
          </p>
          <p>
            ​Please note that we do not use any of your personal information for
            anything else except to contact you directly in response to your
            inquiry. We take the necessary steps to make sure that your
            information is kept confidential. Trust us, we would not even know
            what to do with it anyways. We could bore you with a whole bunch of
            legal terminology that our legal guy wrote down, that only our legal
            guy could read, but essentially we take your privacy very seriously
            and if we have been compromised please let us know so the lawyer guy
            can work with the IT girl to make sure that situation is fixed.
            <a href="/contact">
              If you need to get in touch, you can find us here.
            </a>
          </p>
          <p>
            OCR Academy is Ottawa's first and only obstacle training gym. Come
            for the fun. Come for the challenge. COme to get in the best shape
            of your life. OCR Academy - helping you overcome life's challenges -
            one obstacle at a time.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default TermsOfUse

export const query = graphql`
  {
    file(
      url: {
        eq: "https://api.ocracademy.ca/wp/wp-content/uploads/2019/08/academy-small.png"
      }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
